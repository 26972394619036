import type {
  Breakpoints,
  Colors,
  Fonts,
  Container,
  NavSize,
} from 'types/theme'

import media from 'styles/media'

export const breakpoints: Breakpoints = {
  xs: 0,
  s: 375,
  xsm: 440,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1440,
  xxxl: 1760,
}

export const colors: Colors = {
  primary: '#19355C',
  secondary: '#6D7295',
  dark: '#183A5D',
  dark100: '#183A5D54',
  orange: '#EE7202',
  yellow: '#FEC542',
  gray: '#F0EFF4',
  danger: '#CA7379',
  success: '#2ECC71',
  black: '#000000',
  white: '#FFFFFF',
}

export const fonts: Fonts = {
  size: '10px',
}

export const container: Container = {
  widthMobile: '760px',
  width: '1140px',
  widthXXXL: '1360px',

  paddingMobile: '1.5rem',
  padding: '4rem',
}

export const navSize: NavSize = {
  mobile: '70px',
  desktop: '120px',
}

const theme = {
  breakpoints,
  colors,
  fonts,
  container,
  navSize,
  media: media(),
}

export default theme
